import React, { useEffect, useState } from 'react';
import { Link, useLocation } from '@remix-run/react';

import Icon from '~/components/Icon';
import Login from '~/components/Login';
import Modal from '~/components/Modal';
import { useToast } from '~/components/Toast';
import { useAuth } from '~/contexts/AuthContext';
import { useWebPush } from '~/hooks/useWebPush';
import { cn } from '~/utils/cn';

const FooterLinks = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { user, isAuthenticated, isLoading: isAuthenticating } = useAuth();
  const { showToast } = useToast();
  const { pathname } = useLocation();

  const isHomepage = pathname === '/';

  const {
    isSubscribing,
    isSubscribed,
    error: errorWebPush,
    success: successWebPush,
    requestPermissions: requestPermissionsWebPush
  } = useWebPush();

  // Handle web push notifications status
  useEffect(() => {
    if (successWebPush) {
      showToast('Successfully subscribed to daily notifications', 'success');
    }
  }, [successWebPush, showToast]);

  useEffect(() => {
    if (errorWebPush) {
      showToast(errorWebPush, 'error');
    }
  }, [errorWebPush, showToast]);

  const getWebPushButtonText = () => {
    if (isSubscribing) return 'Subscribing...';
    if (isSubscribed) return 'Subscribed to daily reminder';
    return 'Add a daily reminder';
  };
  return (
    <div className="relative">
      <div className="flex w-fit flex-col gap-2">
        {!isAuthenticated && (
          <button
            type="button"
            onClick={() => {
              if (!isAuthenticated) {
                setIsLoginModalOpen(true);
              }
            }}
            className="h-base flex items-center gap-2"
          >
            <Icon name="account" size={6} className="mb-px" />
            <span className="font-medium text-white">
              {isAuthenticating && 'Logging in...'}
              {!isAuthenticating && 'Sign In'}
            </span>
          </button>
        )}
        {isAuthenticated && isHomepage && (
          <p className="h-base flex items-center gap-2">
            <Icon name="account" size={6} className="mb-px" />
            <span data-cy="auth-user" className="font-medium text-white">
              {user.username}
            </span>
          </p>
        )}
        <Link to="/about" className="h-base flex items-center gap-2">
          <Icon name="smiley" size={6} className="mb-px" />
          About us
        </Link>
        <Link to="/how-it-works" className="h-base flex items-center gap-2">
          <Icon name="question" size={6} className="mb-px" />
          How it works
        </Link>

        {/* WebPush Request Permissions */}
        {/* @TODO: Service Worker isn't supported on this browser, disable or hide UI. */}
        {!isSubscribed && (
          <button
            type="button"
            data-cy="webpush-request-permissions"
            className={cn(
              'h-base flex items-center gap-2',
              isSubscribing || isSubscribed ? 'opacity-50' : ''
            )}
            onClick={requestPermissionsWebPush}
            disabled={isSubscribed || isSubscribing === null}
          >
            <Icon name="calendar" size={6} className="mb-px" />
            {getWebPushButtonText()}
          </button>
        )}

        <Link to="/contribute" className="h-base flex items-center gap-2">
          <Icon name="handStar" size={6} className="mb-px" />
          Contribute
        </Link>
      </div>

      <Modal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        size="small"
        disabled={isAuthenticated}
      >
        <Login />
      </Modal>
    </div>
  );
};

export default FooterLinks;
